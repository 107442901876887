import { render, staticRenderFns } from "./Drawer.vue?vue&type=template&id=546526c3&scoped=true&"
import script from "./Drawer.vue?vue&type=script&lang=js&"
export * from "./Drawer.vue?vue&type=script&lang=js&"
import style0 from "./Drawer.vue?vue&type=style&index=0&id=546526c3&scoped=true&lang=scss&"
import style1 from "./Drawer.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "546526c3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VFlex,VIcon,VNavigationDrawer,VTreeview})
