export default {
  setDrawer: (state, value) => (state.drawer = value),
  setNetworkOnline: (state, value) => (state.networkOnLine = value),
  setSWRegistrationForNewContent: (state, value) => (state.SWRegistrationForNewContent = value),
  setShowAddToHomeScreenModalForApple: (state, value) => (state.showAddToHomeScreenModalForApple = value),
  setRefreshingApp: (state, value) => (state.refreshingApp = value),
  toggleDrawer: state => (state.drawer = !state.drawer),
  setHeaderPosition: state => (state.isFixedHeader = !state.isFixedHeader),
  toggleLoader: state => (state.loader = !state.loader),
  updateGeoPositionState(state) {
		state.geoPosistionState = !state.geoPosistionState
	},
}
