<template>
  <v-app class="white">
    <v-content>
      <CoreToolbar v-show="user" />

      <v-btn
        v-if="user"
        class="mb-12"
        v-show="fab"
        v-scroll="onScroll"
        fab
        dark
        fixed
        bottom
        right
        color="#e25303"
        @click="toTop"
      >
        <v-icon>keyboard_arrow_up</v-icon>
      </v-btn>

      <CoreDrawer v-show="user" />
      <CoreView />

      <BottomNav v-show="user" />

      <new-content-available-toastr
        v-if="newContentAvailable"
        class="new-content-available-toastr"
        :refreshing-app="refreshingApp"
        @refresh="serviceWorkerSkipWaiting"
      />
      
      <apple-add-to-home-screen-modal
        v-if="showAddToHomeScreenModalForApple"
        class="apple-add-to-home-screen-modal"
        @close="closeAddToHomeScreenModalForApple(false)"
      >
      </apple-add-to-home-screen-modal>
    </v-content>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
  </v-app>
</template>
<script>
import Vue from 'vue'
import Component from 'vue-class-component'
import { mapState, mapActions, mapGetters } from 'vuex'

import NewContentAvailableToastr from '@/components/common/NewContentAvailableToastr'
import AppleAddToHomeScreenModal from '@/components/common/AppleAddToHomeScreenModal'

import CoreDrawer from '@/components/common/Drawer'
import CoreToolbar from '@/components/common/Toolbar'
import CoreView from '@/components/common/View'
import BottomNav from '@/components/common/BottomNav'

@Component({
  components: {
    NewContentAvailableToastr,
    AppleAddToHomeScreenModal,
    CoreDrawer,
    CoreToolbar,
    CoreView,
    BottomNav,
  },
  computed: {
    ...mapGetters('app', ['newContentAvailable', 'loader']),
    ...mapState('authentication', ['user']),
    ...mapState('app', ['showAddToHomeScreenModalForApple', 'refreshingApp'])
  },
  methods: {
    ...mapActions('app', [
      'closeAddToHomeScreenModalForApple',
      'serviceWorkerSkipWaiting'
    ])
  }
})
class App extends Vue {
  data() {
    return {
      fab: false
    }
  }

  mounted() {
    const lang = window.localStorage.getItem('lang')
    this.$i18n.locale = lang ? lang : 'no'
  }

  onScroll (e) {
    if (typeof window === 'undefined') return
    const top = window.pageYOffset || e.target.scrollTop || 0
    this.fab = top > 20
  }
  
  toTop () {
    this.$vuetify.goTo(0)
  }
}

export default App

</script>

<style lang="scss">
body {
  .v-application .primary--text {
    color: #fc4c00 !important;
    caret-color: #fc4c00 !important;
  }

  .v-application .deep-orange {
    background-color: #e25303 !important;
    border-color: #e25303 !important;
}


  #app {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    color: #2c3e50;

    .new-content-available-toastr {
      position: absolute;
      bottom: 10px;
      right: 10px;
    }

    .apple-add-to-home-screen-modal {
      position: absolute;
      bottom: 0;
      right: 0;
      top: 0;
      left: 0;
      height: fit-content;
      width: fit-content;
      margin: auto;
      z-index: 1000;
    }

    .main-wrapper {
      margin-top: 3.6rem;
      padding: 20px;

      .page-wrapper {
        width: 60%;
        margin: auto;

        @media screen and (max-width: 1000px) {
          width: 100%;
        }
      }
    }
    .v-input--dense > .v-input__control > .v-input__slot {
      margin-bottom: -16px;
    }
    #app > div > main {
      background-color: white;
    }

    a {
      color: #e25303 !important;
    }
  }
}
</style>
