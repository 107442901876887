// eslint-disable-next-line import/prefer-default-export
export const en = {
  lang: {
    table: 'Table',
    home: 'Home',
    about: 'About',
    my_profile: 'My Profile',
    logout: 'Logout',
    routes_list: 'Routes List',
    add: 'Add',
    create: 'Create',
    save: 'Save',
    update: 'Update',
    delete: 'Delete',
    username: 'Enter your username',
    email: 'Enter email',
    password: 'Enter your password',
    host: 'Enter the host',
    language: 'Language',
    login: 'Login',
    english: 'English',
    norwegian: 'Norwegian',
    polish: 'Polish',
    loggingin: 'Logging in',
    auth_failed: 'Authentication Failed !',
    rememberMe: 'Remember Me',
    refresh: 'Refresh',
    load_content: 'Loading new content...',
    new_content_avail: 'New content available. Please',
    refresh_btn: 'REFRESH',
    install: 'Install',
    install_application: 'Install the app to your device',
    install_app: 'Install this application to your home screen for quickly and easy access when you\'re on the go.',
    tap: 'Just tap',
    add_to_home_screen: 'then \'Add to Home Screen\'',
    invalid_email: 'Invalid Email !',
    required: 'This field is required !',
    search: 'Search',
    active: 'Active',
    route: 'Route',
    place: 'Place',
    address: 'Address',
    containers: 'Containers',
    deviation: 'Deviation',
    comments: 'Comments',
    route_info: 'Route Info',
    not_found: 'Record Not Found',
    went_wrong_msg: 'Something went wrong !',
    vehicle: 'Vehicle',
    cancel: 'Cancel',
    choose_vehicle: 'Choose Vehicle',
    routesDetailsModal: 'Routes Detail Modal',
    updateImage: 'Update Image',
    created_successfully: 'Created Successfully !',
    percentageValidationMsg: 'Please enter correct percentage',
    alreadyRouteInUse: 'Already one route is running, please close that route',
    routeClosedSuccessfully: 'Route has been closed successfully',
    imageUploadSuccess: 'Image has been updated successfully',
    closeConfirm: 'Close confirm',
    closeConfirmMsg: 'Are you sure want to close',
    yes: 'Yes',
    no: 'NO',
    completeAllRoutes: 'Cant close, please complete all the routelists',
    routeIsUsedAnotherDriver: 'This is currently being used by another driver',
    forgotPassword: 'Forgot Password ?',
    somethingWentwrong: 'Something went wrong',
    passwordLinkSent: 'Password reset link is sent to your email, Please check and update your password',
    passwordLinkExpired: 'This link is expired, please create a new one',
    passwordUpdatesucess: 'Your password has been updated successfully',
    passwordRequired: 'Password is required',
    passwordChars: 'Password must be atleast 8 characters.',
    matchPassword: 'Password do not match.',
    enterEmail: 'Enter email',
    submit: 'Submit',
    back: 'Back',
    confirmPassword: 'Confirm Password',
    noWayPoints: 'There are no routelists, You can not start the route',
    emptyTruck: 'Empty the truck',
    fillContainer: 'Prosent fyllegrad',
    routeError: 'Could not calculate a route! Try move start/end point',
    useCurrentPosition: 'Use Current Position',
    start: 'Start',
    edit: 'Edit',
    close: 'Close',
    geoPosition: 'Geo Position',
    updated_successfully : 'Updated successfully',
    drivingTime : 'Driving time',
    save_cage_value : 'You have filled some values in input. To close the route save it or erase the values',
    register_cage: 'Register cage',
    average_fill: 'Avg.fill',
    total_org: 'Total ORG',
    total_rec: 'Total REC',
    saveAndClose: 'Save & Close',
    route_summary: 'Route summary',
    route_categories: 'Route categories',
    org: 'ORG',
    rec: 'REC',
  }
}