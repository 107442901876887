import Vue from 'vue'
import Router from 'vue-router'
import Head from 'vue-head'
import { isNil } from 'lodash'
import VueCookie from 'vue-cookie'

import store from '@/store'

Vue.use(Router)

/* If you don't know about VueHead, please refer to https://github.com/ktquez/vue-head */

Vue.use(Head, {
  complement: process.env.VUE_APP_TITLE
})

/* If you don't know about VueRouter, please refer to https://router.vuejs.org/ */

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        authNotRequired: true
      },
      beforeEnter: (to, from, next) => {
        if (VueCookie.get('authToken')) next('/home')
        else next()
      }
    },

    {
      path: '/forgotpassword',
      name: 'forgotpassword',
      component: () => import('@/views/ForgotPassword.vue'),
      meta: {
        authNotRequired: true
      },
      beforeEnter: (to, from, next) => {
        if (VueCookie.get('authToken')) next('/home')
        else next()
      }
    },

    {
      path: '/password/reset/:token/:email',
      name: 'updatePassword',
      component: () => import('@/views/PasswordReset.vue'),
      meta: {
        authNotRequired: true
      },
      beforeEnter: (to, from, next) => {
        if (VueCookie.get('authToken')) next('/home')
        else next()
      }
    },

    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/HomeView.vue'),
      meta: {
        authNotRequired: false
      }
    },
    {
      path: '/home/:id/:lat?/:lon?',
      name: 'home.edit',
      component: () => import('@/components/home/RouteLists.vue')
    },
    { path: '*', redirect: '/' }
  ]
})

/**
 * Handle user redirections
 */
// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
  if (!(to.meta && to.meta.authNotRequired) && isNil(store.state.authentication.user)) {
    const path =
      store.state.authentication.user === null ||
      store.state.authentication.user === undefined
        ? '/'
        : '/home'
    if (VueCookie.get('authToken')) next()
    else next(`${path}?redirectUrl=${to.path}`)
  }
  next()
})

export default router
