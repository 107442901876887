<template>
  <div class="wrapper">
    <template v-if="refreshingApp">
      {{ $t('lang.load_content') }}
    </template>
    <template v-else>
      {{ $t('lang.new_content_avail') }}
      <span class="refresh-btn" @click="$emit('refresh')">{{ $t('lang.refresh') }}</span>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    refreshingApp: Boolean
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px 20px;
  background-color: #333;
  color: white;
  display: inline-block;
  border-radius: 4px;
  z-index: 100;
  position: fixed !important;
}
.refresh-btn {
  text-decoration: underline;
  cursor: pointer;
}

</style>
