// eslint-disable-next-line import/prefer-default-export
export const no = {
  lang: {
    table: 'Table',
    home: 'Startside',
    about: 'Om',
    my_profile: 'Min profil',
    logout: 'Logg ut',
    routes_list: 'Ruter',
    add: 'Legg til ',
    create: 'opprett',
    save: 'Lagre',
    update: 'Oppdater',
    delete: 'Slett',
    username: 'Skriv inn ditt brukernavn',
    email: 'Skriv inn e-post',
    password: 'Skriv inn passord',
    host: 'Skriv inn domene',
    language: 'Språk',
    login: 'Logg inn',
    english: 'Engelsk',
    norwegian: 'Norsk',
    polish: 'Polsk',
    loggingin: 'Logger inn',
    auth_failed: 'Innlogging feilet !',
    rememberMe: 'Husk meg',
    refresh: 'Oppdater',
    load_content: 'Laster nytt innhold',
    new_content_avail: 'Nytt innhold tilgjengelig',
    refresh_btn: 'Oppdater',
    install: 'Installer',
    install_application: 'Innstaller på din telefon',
    install_app: 'Innstaller denne applikasjonen til din hjem skjerm for rask tilgang.',
    tap: 'Bare berør',
    add_to_home_screen: 'og \'legg til hjem skjerm\'',
    invalid_email: 'Ugyldig epost !',
    required: 'Obligatorisk felt !',
    search: 'Søk',
    active: 'Aktiv',
    route: 'Rute',
    place: 'Sted',
    address: 'Adresse',
    containers: 'Kontainer',
    deviation: 'Avvik',
    comments: 'Kommentar',
    route_info: 'Ruteinfo',
    not_found: 'Ikke funnet',
    went_wrong_msg: 'Noe gikk galt !',
    vehicle: 'Kjøretøy',
    cancel: 'Avvbryt',
    choose_vehicle: 'Velg kjøretøy',
    routesDetailsModal: 'Rutedetaljer',
    updateImage: 'Last opp bilde',
    created_successfully: 'lagt til!',
    percentageValidationMsg: 'Legg inn riktig prosenttall',
    alreadyRouteInUse: 'Du har allerede startet en rute.',
    routeClosedSuccessfully: 'Ruten har blitt avsluttet',
    imageUploadSuccess: 'Bildet ble lastet opp',
    closeConfirm: 'Lukk',
    closeConfirmMsg: 'Er du sikker på at du vil lukke?',
    yes: 'Ja',
    no: 'Nei',
    completeAllRoutes: 'Kan ikke lukke ruten, du har ikke registrert alle lokasjoner',
    routeIsUsedAnotherDriver: 'Denne ruten kjøres av en annen sjåfør',
    forgotPassword: 'Glemt passord?',
    somethingWentwrong: 'Noe gikk galt',
    passwordLinkSent: 'E-post er sendt med resett passord link. Sjekk innboksen din',
    passwordLinkExpired: 'Linken for resett passord har utløpt. Du må sende på nytt.',
    passwordUpdatesucess: 'Passordet ditt ble oppdatert',
    passwordRequired: 'Passord er påkrevet',
    passwordChars: 'Passordet må være på minst 8 tegn.',
    matchPassword: 'Passordet sammenfaller ikke med det du skrev over..',
    enterEmail: 'Skriv inn din epost',
    submit: 'Send',
    back: 'Tilbake',
    confirmPassword: 'Bekreft passord',
    noWayPoints: 'Det er inge loksajoner på denne ruten i dag.',
    emptyTruck: 'Tømming',
    fillContainer: 'Prosent fyllegrad',
    routeError: 'Klarte ikke å beregne rute, prøv med et nytt startpunkt.',
    useCurrentPosition: 'Velg min posisjon som rutestart.',
    start: 'Start',
    edit: 'Endre',
    close: 'Lukk',
    geoPosition: 'Min posisjon',
    updated_successfully : 'Updated successfully',
    drivingTime : 'Driving time',
    save_cage_value : 'You have filled some values in input. To close the route save it or erase the values',
    register_cage: 'Register cage',
    average_fill: 'Avg.fill',
    total_org: 'Total ORG',
    total_rec: 'Total REC',
    saveAndClose: 'Save & Close',
    route_summary: 'Route summary',
    route_categories: 'Route categories',
    org: 'ORG',
    rec: 'REC',
  }
}