<template>
  <v-content id="home" class="pb-0">
    <v-slide-y-reverse-transition>
      <router-view v-show="show" />
    </v-slide-y-reverse-transition>
  </v-content>
</template>

<script>
import Vue from 'vue'
import Component from 'vue-class-component'

@Component({})
class CoreView extends Vue {
  created() {
    this.show = true
  }
}

export default CoreView
</script>
