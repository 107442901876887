import { isNil } from 'lodash'

import router from '@/router'

export default {
  /**
   * Callback fired when user login
   */
  login: async ({ commit }, authData) => {
    commit('authUser', {
      authToken: authData.apiToken,
      userDetails: authData.userDetails
    })
  },

  /**
   * Callback fired when user logout
   */
  logout: ({ commit }) => {
    commit('setUser', null)
    commit('clearAuthData')

    const currentRouter = router.app.$route
    if (!(currentRouter.meta && currentRouter.meta.authNotRequired)) {
      router.push('/login')
    }
  }
}
