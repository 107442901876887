export default {
  setUser: (state, value) => (state.user = value),
  authUser: (state, authData) => {
    state.authToken = authData.authToken;
    state.userDetails = authData.userDetails
    state.isLoggedIn = true;
  },
  clearAuthData: (state) => {
    state.authToken = null
  }
}
