import '@mdi/font/css/materialdesignicons.css' 
import Vue from 'vue';
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

const options = {
  theme: {
    primary: '#518FF5',
    secondary: '#424242',
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107'
  },
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  }
}

export default new Vuetify(options);
