import VueCookie from 'vue-cookie'

import i18n from '@/lang/lang-config'

const lang = VueCookie.get('locale')

/**
 * confirmation
 * @param {Object} self 
 * @param {String} text 
 * @param {String} title 
 * @param {Object} actions 
 */
const confirmation = (
  self,
  text,
  title = '',
  actions = {
    false: i18n.t('lang.no'),
    true: {
      color: 'blue',
      text: i18n.t('lang.yes'),
      handle: () => {
        return new Promise(resolve => (resolve(true)))
      }
    }
  }
) => {
  return self.$dialog.confirm({
    text,
    title,
    actions
  })
}

/**
 * notifications
 * @param {Object} self 
 * @param {String} text 
 * @param {Object} props 
 */
const notifications = (
  self,
  text,
  props = {
    type: 'success',
    position: 'top-right'
  }
) => {
  const dialog = null
  switch (props.type) {
    case 'success':
      self.$dialog.notify.success(text, props)
      break;
    case 'info':
      self.$dialog.notify.info(text, props)
      break;
    case 'warning':
      self.$dialog.notify.warning(text, props)
      break;
    case 'error':
      self.$dialog.notify.error(text, props)
      break;
    default:
      self.$dialog.notify.info(text, props)
      break;
  }
  return dialog
}

/**
 * toasts
 * @param {Object} self 
 * @param {String} text 
 * @param {Object} props 
 */
const toasts = (
  self,
  text,
  type = 'success',
  props = {
    type: 'success',
    position: 'top-right'
  }
) => {
  self.$dialog.message.info(text, type, props)
}



export {
  confirmation,
  notifications,
  toasts
}
