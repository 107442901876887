<template>
  <v-toolbar
    style="background: #e25303 !important"
    height="55px"
    fixed
    :class="{ 'fixed-toolbar': isFixedHeader }"
  >
    <v-app-bar-nav-icon class="hidden-md-and-up" color="white" @click="toggleDrawer" />

    <v-toolbar-title class="ml-0 pl-1 mr-1 d-none d-sm-flex d-sm-none d-md-flex" style="color:white;">
      <!-- <router-link to="/">
        <img
          class="brand-logo"
          src="@/assets/kby.png"
          width="160"
          height="52"
          alt="Logo"
          style="margin-top: 9px;"
        />
      </router-link> -->
      KBY
    </v-toolbar-title>

    <v-spacer />

    <v-switch
      dark
      v-model="geoPosition"
      color="success"
      :label="$t('lang.geoPosition')"
      hide-details
      @click="updateGeoPositionState()"
    ></v-switch>

    <span class="ml-2"></span>
    <v-btn
      v-for="(link, i) in topMenu"
      :key="i"
      :to="link.route"
      class="m4 ml-0 google-font d-none d-sm-flex"
      style="text-transform: capitalize; padding: 0px 8px !important; color: white;"
      text
      autofocus
      router
      @click="onClick(link)"
    >
      <v-icon dark class="mr-1">{{ link.icon }}</v-icon>
    </v-btn>

    <!-- languages menu -->
    <v-menu bottom left offset-y :nudge-bottom="10" :nudge-width="150">
      <template v-slot:activator="{ on }">
        <v-btn text fab dark small v-on="on">
          <v-icon>mdi-web</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item v-for="(item, i) in languages" :key="i">
          <v-list-item-title
            :style="'cursor:pointer;'"
            @click="changeLanguage(item)"
          >
            <span class="mr-2" :class="item.icon" />
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- User menu -->
    <v-menu bottom offset-y :nudge-bottom="10" :nudge-width="150">
      <template v-slot:activator="{ on }">
        <v-btn text fab dark small v-on="on">
          <v-icon>mdi-settings</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item v-for="(item, i) in userMenuItems" :key="i">
          <v-list-item-title v-if="item.username" :style="'cursor:pointer;'" @click="onClick(item)">
            <v-icon class="mr-2">{{ item.icon }}</v-icon>
            {{ loggedInUser }}
          </v-list-item-title>
          <v-list-item-title v-else :style="'cursor:pointer;'" @click="onClick(item)">
            <v-icon class="mr-2">{{ item.icon }}</v-icon>
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-toolbar>
</template>

<script>
import Vue from 'vue'
import Component from 'vue-class-component'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import '@/assets/flag-icon/css/flag-icon.css'

@Component({
  computed: {
    ...mapGetters('app', ['isFixedHeader', 'themeColor', 'geoPosistionState']),
    topMenu() {
      return[{
        title: this.$t('lang.home'),
        icon: 'home',
        route: '/home',
        action: null
      }]
    },
    userMenuItems() {
      return[{
        title: '',
        icon: 'mdi-account-check',
        route: null,
        action: null,
        username: true
      }, {
        title: this.$t('lang.refresh'),
        icon: 'mdi-cached',
        route: null,
        action: this.reloadPage
      }, {
        title: this.$t('lang.logout'),
        icon: 'mdi-logout-variant',
        route: '/cart',
        action: this.logoutUser
      }]
    },

    languages() {
      return [{
        title: this.$t('lang.norwegian'),
        icon: 'flag-icon flag-icon-no',
        locale: 'no',
        route: null,
        action: null
      }, {
        title: this.$t('lang.english'),
        icon: 'flag-icon flag-icon-gb',
        locale: 'en',
        route: null,
        action: null
      }]
    }
  },
  methods: {
    ...mapMutations('app', ['toggleDrawer', 'updateLocale', 'updateGeoPositionState']),
    ...mapActions('authentication', ['logout'])
  }
})
class CoreToolbar extends Vue {

  data() {
    return {
      geoPosition: false,
    }
  }


  mounted() {
    const lang = window.localStorage.getItem('lang')
    this.$i18n.locale = lang ? lang : 'no'
    this.geoPosition = this.geoPosistionState
  }

  get loggedInUser() {
    let loginUser = 'Unknown'
    const userDetails = JSON.parse(this.$cookie.get('user-details'))
    if (userDetails) loginUser = userDetails.first_name

    return loginUser
  }

  get getTopMenus() {
    return this.topMenu
  }

  /**
   * reloadPage
   */
  reloadPage() {
    window.location.reload()
  }

  /**
   * logout
   */
  logoutUser() {
    this.$cookie.delete('authToken')
    this.$cookie.delete('user-details')
    this.logout()
  }

  /**
   * onClick
   * @param { Object } e
   * @param { Object } item
   * @return { void }
   */
  onClick(item) {
    if (item.route && item.route !== this.$router.currentRoute.path) this.$router.push(item.route)
    if(item.action) item.action()
  }

  /**
   *
   */
  changeLanguage(language) {
    this.$i18n.locale = language.locale
    window.localStorage.setItem('lang', language.locale)
    this.$cookie.set('locale', language.locale)
  }
}

export default CoreToolbar
</script>

<style scoped>
  body #app a {
    color: white !important;
  }
</style>
  
</style>