import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookie from 'vue-cookie'
import moment from 'moment'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@/misc/register-service-worker'
import '@/misc/handle-network-status'
import '@/misc/handle-apple-install-prompt'
import 'pwacompat'

import vuetify from '@/plugins/vuetify'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import i18n from '@/lang/lang-config'
import '@/plugins/vuetifyDialog'
import { printFun } from './assets/js/JQuery.print'
import Mixins from './mixins/mixin'

// Print functionality for formio
const $ = require('jquery')

window.$ = $
window.printFun = printFun
// Print end

Vue.use(VueAxios, axios)
Vue.use(VueCookie)
Vue.mixin(Mixins)

Vue.config.productionTip = false

const EventBus = new Vue()

Vue.filter('replaceDotWithComma', function (value) {
  let res = ''
  if (value) res = value.toString().replace('.', ',')
  return res
})

Vue.filter('replceDotWithColon', function (value) {
  let res = ''
  if (value) res = value.toString().replace('.', ':')
  return res
})

Vue.filter('getTimeFromDate', function (value) {
  let res = ''
  if (value) res = moment(value, 'YYYY-MM-DD HH.mm.ss').format('HH:mm')
  return res
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')

export { EventBus }
