<template>
  <!-- <v-card height="5%" class="hidden-sm-and-up" text> -->
    <v-bottom-navigation
       class="hidden-sm-and-up"
      v-model="activeBtn"
      app
      :style="`background: ${themeColor}`"
      color="indigo"
    >
      <v-btn
        v-for="(item, index) in items"
        :id="`menu-${item.id}`"
        :key="index"
        :value="item.title"
        router
        :to="item.route"
      >
        <span style="color: white">{{ item.title }}</span>
        <v-icon color="white">{{ item.icon }}</v-icon>
      </v-btn>
    </v-bottom-navigation>
  <!-- </v-card> -->
</template>

<script>
import Vue from 'vue'
import Component from 'vue-class-component'
import { mapGetters, mapMutations } from 'vuex'

@Component({
  computed: {
    ...mapGetters('app', ['isFixedHeader', 'themeColor']),
    items() {
      return [{
        title: this.$t('lang.home'),
        icon: 'home',
        route: "/home"
      }]
    }
  },
  methods: {
    ...mapMutations('app', ['setHeaderPosition'])
  }
})
class BottomNav extends Vue {
  data () {
    return {
      activeBtn: 1
    }
  }

  /**
   * beforeMount hook
   */
  beforeMount() {
    if(this.$route.path === '/home' && !this.isFixedHeader) this.setHeaderPosition()
  }
}

export default BottomNav
</script>

<style scoped>
  .custom-badge {
    background-color: #F44336 !important;
    border-color: #F44336 !important;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 11px;
    color: #FFFFFF;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 14px;
    height: 22px;
    -webkit-box-pack: center;
    justify-content: center;
    line-height: normal;
    min-width: 22px;
    padding: 0 4px;
    position: absolute;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    right: -5px !important;
    top: 0px !important;
  }

  a.active-bottom-menu span, a.active-bottom-menu i {
    color: black !important; 
  }
</style>