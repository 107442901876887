<template>
  <v-navigation-drawer v-model="stateDrawer" app temporary>
    <v-flex xs12 class="pl-6 pt-1 pb-1 page-title">
      <!-- <img
        class="brand-logo"
        src="@/assets/kby.png"
        width="200"
        height="90"
        alt="Logo"
        style="margin-top: 7px;"
      /> -->
      <h3>KBY</h3>
    </v-flex>

    <!-- Ipad menu -->
    <v-treeview
      v-show="isIpad"
      id="menu-tree"
      :items="menuForIpad"
      hoverable
      activatable
      item-key="name"
      expand-icon="mdi-chevron-down"
      open-on-click
      off-icon="true"
      transition
      class="left-menu"
    >
      <template slot="label" slot-scope="{ item }">
        <v-icon :class="item.class" @click="onClick(item)">{{ item.icon }}</v-icon>
        <a :style="'cursor:pointer;color:#000;'" :data-value="item.route" @click="onClick(item)">{{ item.name }}</a>
      </template>
    </v-treeview>
  </v-navigation-drawer>
</template>

<script>
import Vue from 'vue'
import Component from 'vue-class-component'
import { mapMutations, mapState } from 'vuex'

@Component({
  computed: {
    ...mapState('app', ['drawer']),
    menuForIpad() {
      return [{
        name: this.$t('lang.home'),
        icon: 'home',
        class: 'show-on-ipad',
        route: '/home'
      }]
    }
  },
  methods: {
    ...mapMutations('app', ['setDrawer'])
  }
})
class CoreDrawer extends Vue {
  data() {
    return {
      tree: [],
      ipad: false
    }
  }

  get stateDrawer() {
    return this.drawer
  }

  set stateDrawer(val) {
    this.setDrawer(val)
  }

  get isIpad() {
    return this.ipad
  }

  set isIpad(val) {
    this.ipad = val
  }

  mounted() {
    this.ipad = (window.screen.width >= 600 && window.screen.width < 1024) ? true : false
    const self = this
    const elements = document.querySelectorAll(
      '.left-menu .v-treeview-node--click'
    )
    self.handleMenuLinkClick(elements)

    window.addEventListener("resize", function () {
      self.isIpad = (window.screen.width >= 600 && window.screen.width < 1024) ? true : false
    })
  }

  /**
   * onClick
   * @param { Object } e
   * @param { Object } item
   * @return { void }
   */
  onClick(item) {
    if (item.route) {
      if (item.route && item.route !== this.$router.currentRoute.path) this.$router.push(item.route)
      this.setDrawer(false)
    }
  }

  handleMenuLinkClick(elements) {
    const self = this
    const currentRoute = self.$router.currentRoute
    if (elements.length) {
      elements.forEach(element => {
        element.addEventListener('click', function() {
          const childelements = element.querySelectorAll(
            '.v-treeview-node__children'
          )
          const route = this.querySelector(
            'div.v-treeview-node__label a'
          ).getAttribute('data-value')
          if (route !== null && route !== self.$router.currentRoute.path)
            self.$router.push(route)
          if (childelements.length) {
            self.handleMenuLinkClick(childelements)
          }
        })
      })
    }
  }
}

export default CoreDrawer
</script>

<style scoped lang="scss">
.page-title {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}
</style>

<style>
.v-treeview-node__root {
  padding-left: 23px;
}
</style>