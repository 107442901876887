import Vue from 'vue'
import Component from 'vue-class-component'
import { mapMutations } from 'vuex'
import { notifications } from '@/utils/messages'

@Component({
  methods: {
    ...mapMutations('app', [
      'toggleLoader'
    ])
  }
})
class Mixins extends Vue {

  handleNotificationSuccessOrFainMsg(message, type) {
    notifications(this, message, {
      type: type,
      position: 'top-right'
    })
  }

  formatLength(meters) {
    if (meters < 1000) {
      return meters + "m";
    } else {
      return Math.round(meters / 100) / 10 + "km";
    }
  }

  formatTimeFromMintes(minutes) {
    if (minutes < 61) {
      const min = Math.floor(minutes);
      const sek = Math.round((minutes - min) * 60);
      return min + "min " + this.zeroPadLeft(sek + "", 2) + "sek";
    } else {
      const hours = Math.floor(minutes / 60);
      const min = Math.round(minutes - hours * 60);
      return hours + "h " + this.zeroPadLeft(min + "", 2) + "min";
    }
  }

  zeroPadLeft(val) {
    return val.length < 2 ? "0" + val : val;
  }
  
  handleServerSideValidationError (error, redirect = '/') {
    this.toggleLoader()
    switch(error.status)
    {
      case 404:
        this.handleNotificationSuccessOrFainMsg(error.data.message, 'error')
        this.$router.push(redirect)
        break
        case 422:
          this.handleNotificationSuccessOrFainMsg(Object.values(error.data.errors)[0][0], 'error')
        break
        case 401:
          this.$router.push('/')
        break
        default:
          this.handleNotificationSuccessOrFainMsg(this.$t('lang.went_wrong_msg'), 'error')
          break

    }
  }

  replaceDotWithComma (value) {
    if (value === null || value === undefined || value === '') return ''
    return Number(Math.round(parseFloat(value) + 'e2') + 'e-2').toFixed(2).toString().replace('.', ',')
  }

  replaceDotWithCommaWOFixed (value) {
    if (value === null || value === undefined || value === '') return ''
    return value.toString().replace('.', ',')
  }

  replaceDotWithCommaOneDigit (value) {
    if (value === null || value === undefined || value === '') return ''
    return parseFloat(value).toFixed(1).toString().replace('.', ',')
  }

  replaceCommaWithDot (value) {
    if (!value) return null
    return value.toString().replace(',', '.')
  }

}

export default Mixins